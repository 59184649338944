import React from 'react';
import {HintBulbGlowGif, SychartDoctorDefaultProfile} from '../resource/images';
import AlertModal from './AlertModal';
import {useRef} from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';
import TypingIndicator from './TypingIndicator';
import SychartQuestionArrow from '../resource/svg/SychartQuestionArrow';

const SychartQuestionView = props => {
  const {t} = useTranslation();

  const question =
    (props.question?.descriptor_sign || '') + (props.question?.sign || '') ||
    props?.question;
  const imageUrl = props.question?.image_url;
  const videoUrl = props?.question?.video_url;
  const [showModal, setShowModal] = React.useState(false);
  const multipleQuestion =
    props?.data?.data?.question?.question_type === 'multiple_select';

  const componentRef = useRef(null);

  const isDirRtl = useSelector(isHtmlDirRtl);

  useEffect(() => {
    const height = componentRef.current.clientHeight;
    const scrollHeight = height > 100;
    props.setIsQuestionScroll(scrollHeight);
  }, [props.showQuestion]);

  return (
    <div
      ref={componentRef}
      className={isDirRtl ? 'ar-doctor-chat' : 'doctor-chat'}>
      <img
        src={SychartDoctorDefaultProfile}
        alt="doctor-chating-profile"
        className={isDirRtl ? 'ar-doctor-chat-profile' : 'doctor-chat-profile'}
      />
      <SychartQuestionArrow
        width="20"
        height="21"
        platformPrimaryColor={props.platformPrimaryColor}
        platformPrimaryColorForOpacity={props.platformPrimaryColorForOpacity}
        className={
          isDirRtl ? 'ar-sychart-message-arrow' : 'sychart-message-arrow'
        }
      />
      <div
        className={`${
          isDirRtl
            ? ' ar-doctor-question-background-shadow'
            : ' doctor-question-background-shadow'
        }`}>
        <div className={isDirRtl ? 'ar-bg-white' : 'bg-white'}>
          <div
            className={`chat-container ${
              isDirRtl ? 'ar-doctor-box' : '  doctor-box'
            }`}>
            {props.showQuestion ? (
              <>
                <p className="doctor-question">{question}</p>
                {!!multipleQuestion && (
                  <p className="doctor-multiple-question">
                    {t('select_multiple_answers_if_you_have')}
                  </p>
                )}

                {imageUrl ? (
                  <img
                    src={imageUrl}
                    onClick={props.showImage}
                    alt="questionImage"
                    className="doctot-question-image"
                  />
                ) : (
                  videoUrl && (
                    <video
                      controls
                      onClick={props.showVideo}
                      src={videoUrl}
                      alt="video"
                      className="doctot-question-video"
                    />
                  )
                )}
              </>
            ) : (
              <TypingIndicator />
            )}
          </div>
        </div>
      </div>
      {!!props?.hint && props.showQuestion && (
        <div className={isDirRtl ? 'ar-hint-bulb-div' : 'hint-bulb-div'}>
          <img
            src={HintBulbGlowGif}
            alt="hintGlowBulb"
            className="hint-bulb-size"
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
      <AlertModal
        visible={showModal}
        close={() => setShowModal(false)}
        text={props?.hint || ''}
        header={t('hint')}
      />
    </div>
  );
};
export default SychartQuestionView;
