import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NextArrowIconSvg} from '../resource/svg';
import SychartChatSubHeader from '../commonComponent/SychartChatSubHeader';
import {
  ProgressBars,
  SychartButtonFooter,
  SychartQuestionView,
  SychartSingleAnswer,
} from '../commonComponent';
import SychartOptions from '../commonComponent/SychartOptions';
import './SychartSymtomsList.css';
import {useSychart} from '../../Contexts/SychartContext';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';

function SychartSymtomsList(props) {
  const data = props.data.data;
  const syChart = useSychart();
  const percent = syChart?.questionPerct;
  const [showLoading, setShowLoading] = useState(false);
  const [selelectedId, setSelectedId] = useState(null);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [selectedIndex, setSelectedIndex] = useState(new Set());
  const [multipleLabel, setMultipleLabel] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showBackgroundShadow, setShowBackgroundShadow] = useState(false);
  const [isQuestionScroll, setIsQuestionScroll] = useState(null);
  const [isMultipleSelect, setIsMultipleSelect] = useState(
    props.data.multiple_option_select,
  );
  const {t} = useTranslation();
  const multipleLabelOption = multipleLabel === '' ? t('none') : multipleLabel;

  const isDirRtl = useSelector(isHtmlDirRtl);

  useEffect(() => {
    if (!(selectedIds.size > 0 || selelectedId == 0) && !!data) {
      setShowBackgroundShadow(false);
    }
  }, [data, selectedIds, selelectedId]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(true);
      setTimeout(() => {
        setShowOptions(true);
      }, 200);
    }, 200);

    return () => {
      setShowLoading(false);
      setShowOptions(false);
    };
  }, [data]);

  useEffect(() => {
    setIsMultipleSelect(props.data.multiple_option_select);
    setShowBackgroundShadow(false);
    setDisableBtn(false);
  }, [props.data]);

  useEffect(() => {
    setSelectedId(null);
    setSelectedIds(new Set());
    setSelectedIndex(new Set());
    setDisableBtn(false);
    return () => {
      setSelectedId(null);
      setSelectedIds(new Set());
    };
  }, [data]);

  useEffect(() => {
    setMultipleLabel([]);
    setShowAnswer(false);
    setDisableBtn(false);
  }, [data]);

  let style = {
    border: '1px solid #7A30FE',
    color: '#7A30FE',
    boxShadow: '0px 1px 4px 0px rgba(122, 48, 254, 0.25)',
    background: '#F9F5FF',
  };
  let hoverStyle = {
    background: '#F9F5FF',
    boxShadow: '0px 1px 4px 0px rgba(122, 48, 254, 0.25)',
    border: '1px solid #7A30FE',
    color: '#7A30FE',
  };

  let noneStyle = selelectedId == 0 ? style : {};
  let noneHoverStyles = selelectedId == 0 ? style : hoverStyle;

  const handleClick = (id, item, index) => {
    setSelectedId(id);
    if (isMultipleSelect) {
      if (selectedIds.has(id)) {
        selectedIds.delete(id);
      } else {
        selectedIds.add(id);
      }
      setSelectedIds(new Set([...selectedIds]));
      setShowBackgroundShadow(true);
      setSelectedIndex(prev => {
        const newSet = new Set(prev);

        if (newSet.has(index)) {
          newSet.delete(index);
        } else {
          newSet.add(index);
        }

        const newArray = Array.from(newSet);
        return newArray;
      });
    } else {
      setSelectedIds(new Set([id]));
      setShowBackgroundShadow(true);
    }
    setMultipleLabel(prev => {
      const labels = prev.length > 0 ? prev.split(',') : [];
      const updatedLabels = labels.includes(item)
        ? labels.filter(label => label !== item)
        : [...labels, item];
      return updatedLabels.join(',');
    });
  };

  const onButtonClick = () => {
    setDisableBtn(true);
    setShowAnswer(true);
    setShowOptions(false);
    setShowBackgroundShadow(false);
    setTimeout(() => {
      props.onClick([...selectedIds].join(','), selectedIndex.toString());
      setSelectedId(null);
    }, 2000);
  };

  const colorButton = disabled => {
    return (
      <button
        disabled={disableBtn || disabled}
        className="next-question-button"
        style={{
          backgroundColor:
            disableBtn || disabled ? '#9992A5' : props.platformPrimaryColor,
        }}
        onClick={onButtonClick}>
        <p style={{color: 'white'}}> {t('question_next')}</p>
        <NextArrowIconSvg
          className="next-page-icon"
          style={{transform: isDirRtl ? 'scaleX(-1)' : 'none'}}
        />
      </button>
    );
  };

  const renderOption = () => {
    return data.map((item, index) => {
      let styles = selectedIds.has(item.id) ? style : {};
      let hoverStyles = selectedIds.has(item.id) ? style : hoverStyle;
      return (
        <div
          className="symptoms-option-button"
          onClick={() => handleClick(item.id, item.sign, index)}
          style={{
            ...styles,
          }}
          hoverStyle={{
            ...hoverStyles,
          }}
          key={index}>
          {item.sign}
        </div>
      );
    });
  };

  return (
    <div className="sychart-view">
      <div
        className={
          isQuestionScroll ? 'sychart-mobile-view' : 'sychart-scroll-view '
        }>
        <SychartChatSubHeader
          hint={data?.question?.hint}
          showQuestion={showLoading}
          symtoms
          platformPrimaryColor={props.platformPrimaryColor}
          platformBackgroundColor={props.platformBackgroundColor}
          platformFont={props.platformFont}
        />
        <div
          style={{
            marginBottom: '5%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '25px',
          }}>
          <ProgressBars completed={percent} />
        </div>
        <div style={{padding: '0px 20px', height: '100vh'}}>
          <SychartQuestionView
            question={t('choose_following_symptom')}
            showQuestion={showLoading}
            setIsQuestionScroll={setIsQuestionScroll}
            platformPrimaryColor={props.platformPrimaryColor}
            platformBackgroundColor={props.platformBackgroundColor}
          />
          <div
            style={{
              justifyContent: 'flex-end',
            }}>
            {showAnswer && (
              <SychartSingleAnswer multipleLabels={multipleLabelOption} />
            )}
          </div>
        </div>
        <div
          className={
            !!showBackgroundShadow ? 'option-was-select' : 'option-button-box'
          }>
          <div
            style={{
              maxHeight: '450px',
              overflow: 'auto',
            }}>
            {!!showOptions && (
              <>
                <style>
                  {`
          div::-webkit-scrollbar {
            width: 0.5em;
          }
          div::-webkit-scrollbar-track {
            background: transparent;
          }
          div::-webkit-scrollbar-thumb {
            background: transparent;
          }
        `}
                </style>
                <SychartOptions
                  optionRender={renderOption}
                  setMultipleLabel={setMultipleLabel}
                  setSelectedIds={setSelectedIds}
                  setSelectedId={setSelectedId}
                  setShowBackgroundShadow={setShowBackgroundShadow}
                  noneStyle={noneStyle}
                  noneHoverStyles={noneHoverStyles}
                  symptom
                />
              </>
            )}
          </div>
          <SychartButtonFooter
            button={() =>
              colorButton(
                !(selectedIds.size > 0 || selelectedId == 0) && !!data,
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SychartSymtomsList;
