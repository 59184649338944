import React, {useState, useEffect, useRef} from 'react';
import Utility from '../../commonComponents/Utility';
import {useSychart} from '../../Contexts/SychartContext';
import {useTranslation} from 'react-i18next';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {
  CommonTextInput,
  Loader,
  MediaUploadProgress,
} from '../../commonComponents';
import {Box, Modal, Slider} from '@mui/material';
import Colors from '../../Resources/COLORS/Colors';
import ApiClient from '../../networking/ApiManager';
import {CrossIcon, NextArrowIconSvg, TickIconSvg} from '../resource/svg';
import {
  ProgressBars,
  SychartButtonFooter,
  SychartQuestionView,
  SychartSingleAnswer,
} from '../commonComponent';
import {audioIcon, videoIcon} from '../../Resources/Images';
import SychartChatSubHeader from '../commonComponent/SychartChatSubHeader';
import SychartOptions from '../commonComponent/SychartOptions';
import '../commonComponent/sychartOptions.css';
import './singleSychartQuestion.css';
import '../commonComponent/SychartButtonFooter.css';
import CustomDropdown from '../commonComponent/CustomDropdown';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';

function SingleSychartQuestion(props) {
  const {data, hideNext} = props;
  const [selelectedid, setSelectedId] = useState(null);
  const [showInput, setShowInput] = useState(null);
  const [durationText, setDurationText] = useState(null);
  const [optionDuration, setOptionDuration] = useState(null);
  const [comment, setComment] = useState('');
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [allMediaData, setAllMediaData] = useState([...mediaData]);
  const askForImage = data?.question?.ask_patient_input_image && !Utility.isUae;
  const [isDateField, setIsDateField] = useState(false);
  const [showMediaType, setShowMediaType] = useState('image');
  const [disableBtn, setDisableBtn] = useState(false);
  const [singleMediaProgress] = useState(0);
  let [uploadedCount, setUploadedCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSkipQuestion, setIsSkipQuestion] = useState(false);
  const [skipBtnText, setSkipBtnText] = useState('question_skip');
  const syChart = useSychart();
  const percent = syChart?.questionPerct;
  const [showQuestion, setShowQuestion] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const videoUrl = data?.question?.video_url;
  const audioUrl = data?.question?.audio_url;
  const uploadLimit = 10;
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const questionType = data?.question?.question_type;
  const isNumeric = questionType === 'numeric';
  const textType = questionType === 'text' || isNumeric;
  const isMultipleSelect = questionType === 'multiple_select';
  const [selectedIDS, setSelectedIDS] = useState(new Set());
  const [selectedIndex, setSelectedIndex] = useState(new Set());
  const [showFoterShadow, setFoterShadow] = useState(false);
  const [isQuestionScroll, setIsQuestionScroll] = useState(false);
  const [isOptionScroll, setIsOptionScroll] = useState(false);
  const [images, setImages] = useState();
  const role = localStorage.getItem('role');
  const isDirRtl = useSelector(isHtmlDirRtl);
  const minimumValue = +data?.options[0]?.option_label;
  const lastOptionCode = +data?.options[data?.options.length - 1]?.option_label;
  const textFieldRef = React.useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    props.showSuccess && props.callApi && props.callApi();
  }, [props.id, props.showSuccess]);

  const handleInputFocus = () => {
    Utility.scrollToBottom();
  };

  useEffect(() => {
    if (showInput && bottomRef.current) {
      bottomRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [showInput]);

  useEffect(() => {
    if (
      !!selelectedid &&
      !askForImage &&
      !props.isDurationQuestion &&
      (hideNext || !props.isSymtoms) &&
      questionType !== 'slider'
    ) {
      setShowAnswer(true);
      submitData();
      setComment('');
    }
  }, [selelectedid]);

  useEffect(() => {
    setSelectedId(null);
    setDurationText('');
    setComment('');
    setMediaData([]);
    setAllMediaData([]);
    setLoading(false);
    setFoterShadow(false);
    setIsSkipQuestion(data?.question?.skippable);
    props.setDate(false);
    setDisableBtn(false);
    setSelectedIndex(new Set());
    setShowAnswer(false);
    setShowInput(null);
    setTimeout(() => {
      setShowQuestion(true);
      setTimeout(() => {
        setShowOptions(true);
      }, 200);
    }, 200);

    return () => {
      setShowQuestion(false);
      setShowOptions(false);
    };
  }, [data]);

  useEffect(() => {
    return () => {
      setSelectedIDS(new Set());
      setSelectedId(null);
    };
  }, [data]);

  useEffect(() => {
    if (mediaData.length > 0) {
      allMediaData.length <= uploadLimit &&
        setAllMediaData([...allMediaData, ...mediaData]);
      setMediaData([]);
    }
    if (allMediaData.length > uploadLimit) {
      setAllMediaData(allMediaData.slice(0, uploadLimit));
    }
  }, [mediaData, mediaData.length]);

  useEffect(() => {
    if (questionType === 'slide') {
      handleChangeSlider(null, minimumValue);
    }
  }, [data]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
  }, [comment?.length]);

  useEffect(() => {
    if (!(selectedIDS.size > 0 || selelectedid == 0) && !!data) {
      setFoterShadow(false);
    }
  }, [data, selectedIDS, selelectedid]);

  const handleChange = (item, index, isOther) => {
    if (isMultipleSelect) {
      setSelectedIDS(prev => {
        if (prev.has(item.id)) {
          prev.delete(item.id);
        } else {
          prev.add(item.id);
        }
        return new Set(prev);
      });
      setSelectedIndex(prev => {
        const newSet = new Set(prev);

        if (newSet.has(index)) {
          newSet.delete(index);
        } else {
          newSet.add(index);
        }

        const newArray = Array.from(newSet);
        return newArray;
      });
    } else if (isOther) {
      setShowInput(item.id);
      setSelectedIndex(index);
      setSelectedId(null);
    } else if (showInput && selelectedid) {
      setShowInput(false);
    } else {
      setSelectedId(item.id);
      setSelectedIndex(index);
      setAnswer(item.option_label);
      setIsDateField(item.option_code == 'date');
      setDurationText('');
    }
  };

  const enableNextButton = () => {
    const showBtn = !!props.isDurationQuestion
      ? !!durationText && !!selelectedid && !!data
      : !!selelectedid && !!data;
    const showBtn2 =
      !!askForImage &&
      !!(comment && allMediaData.length > 0) &&
      !!(data?.options.length == 0);
    const showBtn3 = !!isDateField && !!props.showDate && data;

    let activeBtn = false;
    if (!!isDateField) {
      activeBtn = !!showBtn && !!showBtn3;
    } else {
      activeBtn = showBtn;
    }

    const showBtn4 = !!isMultipleSelect && selectedIDS.size > 0 && !!data;

    return activeBtn || showBtn2 || showBtn4;
  };

  const showProgress = () => {
    return (
      <MediaUploadProgress
        progress={singleMediaProgress}
        uploaded={uploadedCount}
        length={allMediaData.length}
      />
    );
  };

  const showModal = () => {
    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          onClick={() => setOpen(false)}
          sx={{
            height: '100%',
            width: '100%',
            background: '##00000000 ',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // resize:""
          }}>
          {showMediaType == 'image' && (
            <div
              style={{
                backgroundColor: 'white',
                padding: 15,
                display: 'flex',
                borderRadius: 10,
                position: 'relative',
              }}>
              <CrossIcon
                style={{position: 'absolute', right: '1%', top: '2%'}}
                primaryColor={props.platformPrimaryColor}
              />
              <img
                src={data?.question?.image_url}
                alt="question"
                style={{
                  margin: 'auto',
                  minWidth: '250px',
                  borderRadius: '10px',
                  maxWidth: 300,
                  maxHeight: 300,
                }}
              />
            </div>
          )}
          {showMediaType == 'video' && (
            <video
              controls
              src={videoUrl}
              style={{minWidth: '300px', maxWidth: '100vw', maxHeight: '99vh'}}
            />
          )}
          {showMediaType == 'audio' && (
            <audio
              controls
              src={audioUrl}
              style={{minWidth: '300px', maxWidth: '80vw', maxHeight: '99vh'}}
            />
          )}
        </Box>
      </Modal>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <SychartChatSubHeader
          hint={data?.question?.hint}
          showQuestion={showQuestion}
        />
        <div
          style={{
            marginBottom: '5%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '25px',
          }}>
          <ProgressBars completed={percent} />
        </div>
        {showModal()}
      </>
    );
  };

  const deleteMedia = id => {
    let newMediaData = allMediaData.filter((item, index) => index !== id);
    setAllMediaData(newMediaData);
  };

  const showMedia = () => {
    if (showMediaModal || showMediaModal === 0) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: Colors.WHITE,
            zIndex: '100',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setShowMediaModal(null)}>
          <img
            src={URL.createObjectURL(allMediaData[showMediaModal])}
            alt={'upload-img'}
            style={{
              margin: 'auto',
              maxWidth: '80vw',
              maxHeight: '80vh',
              objectFit: 'contain',
            }}
          />
        </div>
      );
    }
  };

  const showUploadedMedia = () => {
    if (allMediaData && allMediaData.length > 0) {
      return allMediaData.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              margin: 'auto 10px',
              width: '110',
              height: '110px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              position: 'relative',
            }}>
            <img
              alt={'uploaded-img'}
              style={{
                width: '110px',
                height: '110px',
                objectFit: 'contain',
              }}
              src={URL.createObjectURL(item)}
              onClick={() => setShowMediaModal(index)}
            />

            <CrossIcon
              style={{
                position: 'absolute',
                top: '-8px',
                right: '-13px',
              }}
              primaryColor={props.platformPrimaryColor}
              onClick={() => deleteMedia(index)}
            />
          </div>
        );
      });
    }
  };

  const submitData = async urls => {
    setFoterShadow(false);
    setShowOptions(false);
    setTimeout(() => {
      if (isMultipleSelect) {
        props.onClick(
          [...selectedIDS].join(','),
          data?.question?.id,
          durationText,
          urls,
          selectedIndex.toString(),
        );
      } else if (textType) {
        props.onClick(null, data?.question?.id, null, urls, null, {
          comment: comment,
        });
      } else if (showInput) {
        props.onClick(
          showInput,
          data?.question?.id,
          durationText,
          urls,
          selectedIndex.toString(),
          {
            comment: comment,
          },
        );
      } else if (questionType === 'slider' || props.isDurationQuestion) {
        props.onClick(
          selelectedid,
          data?.question?.id,
          durationText,
          urls,
          null,
        );
      } else {
        props.onClick(
          selelectedid,
          data?.question?.id,
          durationText,
          urls,
          selectedIndex.toString(),
          props.showDate,
        );
      }
      setImages([]);
      setUploadedCount(false);
      setAllMediaData([]);
      setLoading(false);
    }, 2000);
  };

  const uploadMediaToServer = async data => {
    setLoading(true);
    let urls = [];

    for (let itemIndex in data) {
      await ApiClient.uploadFileToServer(
        allMediaData[itemIndex],
        data[itemIndex].url,
      )
        .then(() => {
          urls.push({url: data[itemIndex].url});
          setLoading(false);
        })
        .catch(err => {
          console.log(err.response);
          setLoading(false);
        });

      if (itemIndex == data.length - 1) {
        setSkipBtnText(t('question_next'));
        setShowAnswer(true);
        await submitData(urls);
      }
    }
  };

  const getUrlForMedia = () => {
    const params = {
      count: allMediaData.length,
      type: 'patient_responses',
    };

    setLoading(true);
    ApiClient.presignedUrls(params)
      .then(res => {
        let mediaData = res.data.data;
        setImages(mediaData);
        uploadMediaToServer(mediaData);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getNurseUrlForMedia = () => {
    const params = {
      count: allMediaData.length,
      type: 'patient_responses',
    };

    setLoading(true);
    ApiClient.presignedUrlsForNurse(params)
      .then(res => {
        let mediaData = res.data.data;
        setImages(mediaData);
        uploadMediaToServer(mediaData);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const newSychartpresignedUrls = () => {
    const params = {
      count: allMediaData.length,
      type: 'patient_responses',
    };
    setLoading(true);
    ApiClient.newSychartpresignedUrls(params, props.id)
      .then(res => {
        let mediaData = res.data.data;
        setImages(mediaData);
        uploadMediaToServer(mediaData);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleBtnClick = () => {
    setDisableBtn(true);
    if (allMediaData.length > 0) {
      if (props.baseUrl) {
        newSychartpresignedUrls();
      } else {
        role === 'nurse' ? getNurseUrlForMedia() : getUrlForMedia();
      }
    } else {
      setShowAnswer(true);
      submitData();
    }
  };

  const showAudio = () => {
    setShowMediaType('audio');
    setOpen(true);
  };

  const showVideo = () => {
    setShowMediaType('video');
    setOpen(true);
  };

  const showImage = () => {
    setShowMediaType('image');
    setOpen(true);
  };

  const renderAudioVideo = () => {
    if (audioUrl || videoUrl) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '15px 10px 25px 10px',
          }}>
          {videoUrl && (
            <img
              onClick={showVideo}
              alt={'video-icon'}
              src={videoIcon}
              style={{
                width: '35px',
                padding: '0 10px',
                margin: '0 3px',
              }}
            />
          )}
          {audioUrl && (
            <img
              onClick={showAudio}
              alt={'audio-img'}
              src={audioIcon}
              style={{
                width: '35px',
                padding: '0 10px',
                margin: '0 3px',
              }}
            />
          )}
        </div>
      );
    }
  };

  const colorButton = (onClick, text, disabled) => {
    if (
      data.question.question_type === 'single_select' &&
      !askForImage &&
      !props.isDurationQuestion &&
      !comment
    ) {
      return;
    } else {
      return (
        <button
          disabled={disableBtn || disabled}
          className="next-question-button"
          style={{
            backgroundColor:
              disableBtn || disabled ? '#9992A5' : props.platformPrimaryColor,
          }}
          onClick={onClick ? onClick : () => {}}>
          <p style={{color: 'white'}}> {t(text)}</p>
          <NextArrowIconSvg
            className="next-page-icon "
            style={{transform: isDirRtl ? 'scaleX(-1)' : 'none'}}
          />
        </button>
      );
    }
  };

  const renderButton = () => {
    if (enableNextButton()) {
      return colorButton(handleBtnClick, t('question_next'));
    } else if (isSkipQuestion && allMediaData.length > 0) {
      return colorButton(handleBtnClick, t('question_next'));
    } else if (showInput && comment.trim() !== '') {
      return colorButton(handleBtnClick, t('question_next'));
    } else if (isSkipQuestion && allMediaData.length == 0) {
      return colorButton(handleBtnClick, skipBtnText);
    } else if (textType && comment) {
      return colorButton(handleBtnClick, t('question_next'));
    } else if (!!durationText) {
      return colorButton(handleBtnClick, t('question_next'));
    } else {
      return data && colorButton(false, t('question_next'), true);
    }
  };

  const durationTextKey = (option, optionDuration) => {
    setDurationText(option);
    setOptionDuration(optionDuration);
  };

  const renderQuestionOptions = () => {
    return (
      <>
        {data?.options?.map((item, index) => {
          const isYesNo =
            item?.option_label === 'Yes' || item?.option_label === 'No';
          let className = '';
          let tickSvgDisabled = '';
          const isSelected =
            +selelectedid === +item.id || [...selectedIDS].includes(+item.id);
          if (isSelected) {
            className = 'option-selected';
            setFoterShadow(true);
          } else {
            tickSvgDisabled = 'disabled-tickIcon';
          }
          return (
            <>
              {!props.isDurationQuestion ? (
                <>
                  <button
                    key={index}
                    className={`${
                      isYesNo
                        ? 'yes-no-style'
                        : !!isMultipleSelect
                        ? 'sychart-option-left-button'
                        : 'sychart-option-button'
                    } ${className}`}
                    onClick={() => {
                      handleChange(item, index);
                    }}>
                    {!!isMultipleSelect && (
                      <TickIconSvg
                        color={props.platformPrimaryColor}
                        style={{minWidth: 14}}
                        className={tickSvgDisabled}
                      />
                    )}
                    <>
                      {item.option_code !== 'date' ? (
                        <div>{item.option_label}</div>
                      ) : (
                        <div
                          onClick={() => {
                            document.getElementById('dateInput').focus();
                          }}
                          style={{margin: 'auto', fontWeight: 25}}>
                          Choose A Date...
                        </div>
                      )}
                      {item.option_code == 'date' && data && (
                        <div
                          style={{
                            margin: 'auto',
                            textAlign: 'justify',
                          }}>
                          <input
                            id="dateInput"
                            style={{
                              border: 'none',
                              outline: 'none',
                              fontSize: '18px',
                              margin: ' -10px',
                              textAlign: 'right',
                              background: 'none',
                              display: 'block',
                            }}
                            type="date"
                            name="date"
                            max="9999-12-31"
                            value={props.showDate}
                            onChange={e => props.setDate(e.target.value)}
                          />
                        </div>
                      )}
                    </>
                  </button>
                </>
              ) : (
                <CustomDropdown
                  item={item}
                  durationTextKey={durationTextKey}
                  options={data?.options}
                  setSelectedId={setSelectedId}
                  platformPrimaryColor={props.platformPrimaryColor}
                />
              )}
            </>
          );
        })}
      </>
    );
  };

  const handleChangeSlider = (event, newValue) => {
    const foundItem = data?.options.find(
      item => item?.option_label == newValue,
    );
    setSelectedId(foundItem?.id);
    setAnswer(newValue);
  };

  const theme = createTheme({
    direction: isDirRtl ? 'rtl' : 'ltr',
  });

  const renderCharacterCount = () => {
    return (
      <div
        className="character-count"
        style={{
          color: comment?.length >= 2000 ? 'red' : 'black',
        }}>
        {comment?.length > 500 ? comment?.length + '/' + 2000 : null}
      </div>
    );
  };

  const renderUserInputBox = () => {
    const additionalInputString = data?.options.some(
      item => item?.additional_input_type === 'string',
    );
    if (textType) {
      enableNextButton();
      return (
        <CommonTextInput
          ref={textFieldRef}
          multiline
          minRows={3}
          variant="standard"
          className="comment-text"
          style={{
            padding: '10px 15px',
          }}
          onChange={setComment}
          maxLength={2000}
          placeholder={isNumeric ? t('type_number_here') : t('type_text_here')}
          onFocus={handleInputFocus}
          value={comment}
          validateDeciNumbers={isNumeric}
          autoFocus={true}
          platformPrimaryColor={props.platformPrimaryColor}
          InputProps={{
            disableUnderline: true,
            endAdornment: renderCharacterCount(),
            startAdornment: <div />,
            inputProps: {
              inputMode: isNumeric ? 'numeric' : 'text',
            },
          }}
        />
      );
    } else if (questionType === 'slider') {
      const isOptionsLengthEven = data.options.length % 2 === 0;

      const marks = data.options.map((item, index) => ({
        value: isOptionsLengthEven ? index * 2 : index * 2 + 1,
        label: (isOptionsLengthEven ? index * 2 : index * 2 + 1).toString(),
      }));

      return (
        <ThemeProvider theme={theme}>
          <Box sx={{width: '100%'}}>
            <Slider
              defaultValue={minimumValue}
              step={1}
              min={minimumValue}
              marks={marks}
              onChange={handleChangeSlider}
              valueLabelDisplay="on"
              style={{
                color: props.platformPrimaryColor,
                height: '7px',
                padding: '17px 0px',
              }}
              max={lastOptionCode}
              sx={{
                '& .MuiSlider-valueLabel': {
                  backgroundColor: props.platformPrimaryColor,
                },
                '& .MuiSlider-thumb': {
                  marginRight: isDirRtl ? -2 : 0,
                },
                '.MuiSlider-markLabel': {
                  transform: isDirRtl ? 'translateX(25%)' : 'translateX(-50%)',
                },
                '.MuiSlider-rail': {
                  transform: isDirRtl
                    ? 'translateX(-1%) translateY(-50%)'
                    : 'translateX(1%) translateY(-50%)',
                },
              }}
            />
          </Box>
        </ThemeProvider>
      );
    } else if (additionalInputString) {
      return (
        <>
          {data?.options?.map((item, index) => {
            const isOther = item?.additional_input_type === 'string';
            const isSelected = +selelectedid === +item.id;

            if (showInput && isSelected) {
              setShowInput(false);
            }

            return (
              <div>
                <div key={index} className="option-container">
                  <div
                    className={
                      (!!showInput && isOther) || selelectedid == item.id
                        ? 'select-sychart-option-button'
                        : 'sychart-option-button'
                    }
                    onClick={() => handleChange(item, index, isOther)}
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                    }}>
                    {item.option_label}
                  </div>
                </div>
              </div>
            );
          })}
          <div
            style={{
              width: '92.5%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {showInput && (
              <div
                style={{
                  width: '100%',
                }}>
                <CommonTextInput
                  ref={textFieldRef}
                  multiline
                  minRows={3}
                  variant="standard"
                  className="comment-text"
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  }}
                  onChange={setComment}
                  maxLength={2000}
                  placeholder={
                    isNumeric ? t('type_number_here') : t('type_text_here')
                  }
                  onFocus={handleInputFocus}
                  value={comment}
                  autoFocus={true}
                  platformPrimaryColor={props.platformPrimaryColor}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: renderCharacterCount(),
                    startAdornment: <div />,
                    inputProps: {
                      inputMode: isNumeric ? 'numeric' : 'text',
                    },
                  }}
                />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return renderQuestionOptions();
    }
  };

  return (
    <div className="sychart-view">
      <div
        className={
          isQuestionScroll ? 'sychart-mobile-view' : 'sychart-scroll-view '
        }>
        {renderHeader()}
        <div
          style={{
            paddingLeft: '24px',
            paddingRight: '6px',
            height: '100vh',
          }}>
          <div
            style={{
              maxHeight: isQuestionScroll ? '55vh' : 'none',
              overflow: isQuestionScroll ? 'auto' : 'none',
              overflowX: 'hidden',
            }}>
            <SychartQuestionView
              question={data?.question}
              showImage={showImage}
              showVideo={showVideo}
              showQuestion={showQuestion}
              hint={data?.question?.hint}
              data={syChart.data}
              setIsQuestionScroll={setIsQuestionScroll}
              platformPrimaryColor={props.platformPrimaryColor}
              platformBackgroundColor={props.platformBackgroundColor}
              platformPrimaryColorForOpacity={
                props.platformPrimaryColorForOpacity
              }
            />
            {isQuestionScroll && isOptionScroll && showOptions && (
              <div
                style={{
                  height: 95,
                  width: 30,
                  marginBottom: 20,
                }}
              />
            )}
          </div>

          <div>
            {showAnswer && (
              <SychartSingleAnswer
                durationText={optionDuration}
                answer={answer}
                data={syChart.data}
                text={comment}
                showInput={showInput}
                selectedIds={selectedIDS}
              />
            )}
            {!!images?.length > 0 && showAnswer && (
              <SychartSingleAnswer
                durationText={optionDuration}
                data={syChart.data}
                text={comment}
                images={allMediaData}
                selectedIds={selectedIDS}
                hide
              />
            )}
          </div>
        </div>
        {renderAudioVideo()}
        <div
          className={
            showFoterShadow ? 'option-was-select' : 'option-button-box'
          }>
          {showOptions && (
            <SychartOptions
              slider={questionType === 'slider'}
              optionRender={renderUserInputBox}
              lastQuestId={props.lastQuestId}
              hideNext={hideNext}
              showInput={showInput}
              setIsOptionScroll={setIsOptionScroll}
            />
          )}
          {askForImage && allMediaData.length > 0 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                padding: '10px 0px',
                overflow: 'auto',
              }}>
              {!showAnswer && showUploadedMedia()}
            </div>
          )}

          <SychartButtonFooter
            hideNext={hideNext}
            showInput={showInput}
            button={renderButton}
            renderUserInputBox={renderUserInputBox}
            askForImage={askForImage}
            setMediaData={setMediaData}
            platformPrimaryColor={props.platformPrimaryColor}
            platformBackgroundColor={props.platformBackgroundColor}
            platformPrimaryColorForOpacity={
              props.platformPrimaryColorForOpacity
            }
          />
        </div>
        {showMedia()}
        {!uploadedCount && <Loader loading={loading} />}
        {!!uploadedCount && showProgress()}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            bottom: 0,
          }}></div>
      </div>
    </div>
  );
}

export default SingleSychartQuestion;
