import React, {useEffect} from 'react';
import './chat.css';
import apis from './apis';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import Markdown from 'react-markdown';
import {Loader} from '../../commonComponents';
import remarkGfm from 'remark-gfm';
import {LinkIconSvg} from '../../newSychart/resource/svg';
import useActionCable from './useActionCable';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

const previousUrl = new URL(window.location.href).searchParams.get(
  'referrer_url',
);
const chatBotPopUp = new URL(window.location.href).searchParams.get(
  'chatbot_popup',
);

const ChatRoute = ({syLocale}) => {
  const CHANNEL = 'ChatBotChannel';
  const [messages, setMessages] = React.useState([]);
  const messagesListRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const token = localStorage.getItem('token');
  const [, setTimeUpdateTrigger] = React.useState(0);

  const getMessages = () => {
    apis.getMessages().then(res => {
      setMessages(res.data.data.reverse());
    });
  };

  const {
    loading,
    getMessage,
    setIsBtnDisable,
    isbtnDisable,
    isInputDisable,
    setIsInputDisable,
  } = useActionCable(token, CHANNEL, getMessages);

  TimeAgo.addLocale(en);

  const {t} = useTranslation();
  const isArabic = syLocale === 'ar';
  const isHindi = syLocale === 'hi';

  useEffect(() => {
    if (isHindi) {
      moment.updateLocale('hi', {
        relativeTime: {
          future: '%s में',
          past: '%s पहले',
          s: 'कुछ ही क्षण',
          ss: '%d सेकंड',
          m: '1 मिनट',
          mm: '%d मिनट',
          h: '1 घंटे',
          hh: '%d घंटे',
          d: '1 दिन',
          dd: '%d दिन',
          w: '1 सप्ताह',
          ww: '%d सप्ताह',
          M: '1 महीने',
          MM: '%d महीने',
          y: '1 साल',
          yy: '%d साल',
        },
      });
      moment.locale('hi');
    } else if (isArabic) {
      moment.updateLocale('ar', {
        relativeTime: {
          future: 'بعد %s',
          past: 'منذ %s',
          s: 'منذ ثوان',
          ss: 'منذ %d ثانية',
          m: 'منذ دقيقة',
          mm: 'منذ %d دقائق',
          h: 'منذ ساعة',
          hh: 'منذ %d ساعات',
          d: 'منذ يوم',
          dd: 'منذ %d أيام',
          w: 'منذ أسبوع',
          ww: 'منذ %d أسابيع',
          M: 'منذ شهر',
          MM: 'منذ %d أشهر',
          y: 'منذ سنة',
          yy: 'منذ %d سنوات',
        },
      });
      moment.locale('ar');
    } else {
      moment.locale('en-gb');
    }
  }, [syLocale]);

  useEffect(() => {
    const handleResize = () => {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const chatBotName = 'Shanti';
  const isEmpty = value =>
    value.length === 0 ||
    value.trim().length === 0 ||
    value.trim().charAt(0) === ' ';

  const scrollToBottom = () => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    document.body.style.backgroundColor = '#e2e7f5';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeUpdateTrigger(prev => prev + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const sendMessage = () => {
    const message = inputRef.current.value;
    if (isEmpty(message)) {
      return;
    }
    setIsBtnDisable(true);
    setIsInputDisable(true);
    const updateMessageList = async msg => {
      setMessages(prev => [...prev, msg]);
      const cableMessage = await getMessage();
      if (cableMessage) {
        setMessages(prev => [...prev, cableMessage]);
      }
      window.scrollTo(0, document.body.scrollHeight);
    };

    apis.sendMessage({message}).then(res => {
      updateMessageList(res.data.data);
      inputRef.current.value = '';
    });
  };

  const onIconClick = e => {
    e.preventDefault();
    sendMessage();
    e.target.closest('form').reset();
  };

  const onFormClick = e => {
    e.preventDefault();
    sendMessage();
    e.target.children[0].value = '';
  };

  const renderCurrentUserMessage = (message, index) => {
    const dateWithCurrentYear = (() => {
      const createdAt = message?.created_at?.trim();
      const [dayMonth, time] = createdAt?.split(',');
      const [day, month] = dayMonth.split(' ');
      const [hour, minute] = time.split(':');
      const currentYear = moment().format('YYYY');
      return `${day} ${month}, ${hour}:${minute} ${currentYear}`;
    })();

    const createdAtTimestamp = moment(dateWithCurrentYear).fromNow();

    const messageStyle = {
      backgroundImage:
        'url(https://cdn.mydocsy.com/production/assets/250-docsy-shanti-profile-image-1712833509.webp)',
    };
    const isTyping = index === messages.length - 1;

    return (
      <>
        <div className="user-message">
          <div className="message text-only">
            <div className="response">
              <p className="text">
                <Markdown>{message.message}</Markdown>
              </p>
            </div>
          </div>
          <p className="time response-time">{createdAtTimestamp}</p>
        </div>
        {isTyping && (
          <div className="message bot-message">
            <div className="message bot-message">
              <div className="photo" style={messageStyle}>
                <div className="online"></div>
              </div>
              <pre className="text bot-reply">
                <Markdown>{t('typing')}</Markdown>
              </pre>
            </div>
          </div>
        )}
      </>
    );
  };

  const exitChatbot = () => {
    window.parent.location.href = previousUrl;
  };

  const renderBotMessage = (message, index) => {
    const {created_at, message: botMessage} = message;

    const dateWithCurrentYear = (() => {
      const createdAt = created_at?.trim();
      const [dayMonth, time] = createdAt?.split(',');
      const [day, month] = dayMonth.split(' ');
      const [hour, minute] = time.split(':');
      const currentYear = moment().format('YYYY');
      return `${day} ${month}, ${hour}:${minute} ${currentYear}`;
    })();

    const createdAtTimestamp = moment(dateWithCurrentYear).fromNow();

    const isTyping = index === messages.length - 1;

    const messageStyle = {
      backgroundImage:
        'url(https://cdn.mydocsy.com/production/assets/250-docsy-shanti-profile-image-1712833509.webp)',
    };

    const handleLinkClick = link => {
      window.open(link, '_blank');
    };

    const LinkRenderer = ({href, children}) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );

    return (
      <div key={index}>
        <div className="message bot-message">
          <div className="photo" style={messageStyle}>
            <div className="online" />
          </div>
          <pre className="text bot-reply">
            <Markdown
              components={{a: LinkRenderer}}
              remarkPlugins={[remarkGfm]}>
              {botMessage}
            </Markdown>
            <ul className="message-options-view">
              {message.options?.map(option => (
                <li className="message-option-text">{option}</li>
              ))}
            </ul>
            {message.links.length > 0 && <p className="sep-line"></p>}
            <div className="link-btn-container">
              {message.links.map((link, i) => (
                <div
                  key={i}
                  className="icon-container"
                  onClick={() => handleLinkClick(link.link)}>
                  <LinkIconSvg />
                  <p className="link-btn-text">{link.title}</p>
                </div>
              ))}
            </div>
          </pre>
        </div>
        <p className="time">
          {!botMessage && isTyping ? null : createdAtTimestamp}
        </p>
      </div>
    );
  };

  const onChange = text => {
    if (isEmpty(text)) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  };

  return (
    <div className="chatbot-container w-100">
      {loading ? (
        <Loader loading />
      ) : (
        <section className="chatbot">
          <div className="header-chat">
            {!chatBotPopUp && (
              <img
                src="https://cdn.mydocsy.com/production/assets/249-docsy-primary-back-icon-1710411442.webp"
                alt="docsy back primary icon"
                onClick={() => exitChatbot()}
                className="exit-chatbot"
              />
            )}
            <img
              src="https://cdn.mydocsy.com/production/assets/250-docsy-shanti-profile-image-1712833509.webp"
              alt="docsy shanti profile"
              className="chatbot-header-logo img-fluid"
            />
            <p className="name">{t(chatBotName)}</p>
          </div>
          <div className="chat-body">
            <div className="messages-chat" ref={messagesListRef}>
              {messages.map((message, index) => {
                return message.sender_id === message.user_id
                  ? renderCurrentUserMessage(message, index)
                  : renderBotMessage(message, index);
              })}
            </div>
            <div className="footer-chat">
              <form className="chat-input-form" onSubmit={onFormClick}>
                <input
                  className="chat-input write-message"
                  placeholder={
                    isInputDisable
                      ? t('please_wait_for_shanti_to_reply')
                      : t('please_type_your_message_here')
                  }
                  onChange={e => onChange(e.target.value)}
                  onPaste={e => onChange(e.clipboardData.getData('text/plain'))}
                  onInput={e => onChange(e.target.value)}
                  ref={inputRef}
                  disabled={isInputDisable}
                />
                {isbtnDisable ? (
                  <img
                    src="https://cdn.mydocsy.com/production/assets/247-docsy-chatbot-disable-send-message-icon-1710172923.webp"
                    alt="docsy chatbot disable send message icon"
                    className="send-message-icon img-fluid"
                  />
                ) : (
                  <img
                    src="https://cdn.mydocsy.com/production/assets/248-docsy-chatbot-active-send-message-primary-icon-1710411377.webp"
                    alt="docsy chatbot active send message primary icon"
                    className="send-message-icon img-fluid"
                    onClick={onIconClick}
                  />
                )}
              </form>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ChatRoute;
