import React, {useRef, useEffect} from 'react';
import './sychartOptions.css';
import {t} from 'i18next';

const SychartOptions = ({
  optionRender,
  key,
  setSelectedIds,
  setSelectedId,
  setMultipleLabel,
  setShowBackgroundShadow,
  noneStyle,
  noneHoverStyles,
  symptom,
  slider,
  lastQuestId,
  hideNext,
  setIsOptionScroll,
  showInput,
}) => {
  const optionClass = slider
    ? 'slider-question-option'
    : !lastQuestId && hideNext
    ? 'lastQ-question-option'
    : 'question-option';
  const componentRef = useRef(null);

  useEffect(() => {
    if (showInput) {
      const element = componentRef.current;
      if (element) {
        requestAnimationFrame(() => {
          element.scrollTop = element.scrollHeight;
        });
      }
    }
  }, [showInput]);

  useEffect(() => {
    const height = componentRef.current?.clientHeight;
    const scrollHeight = height > 150;
    setIsOptionScroll(scrollHeight);
  }, [setIsOptionScroll]);

  return (
    <div ref={componentRef} className={optionClass} key={key}>
      {optionRender()}
      {!!symptom && (
        <div
          onClick={() => {
            setSelectedIds(new Set());
            setSelectedId(0);
            setMultipleLabel('');
            setShowBackgroundShadow(true);
          }}
          className="symptoms-option-button none-symptom-option"
          style={{...noneStyle}}
          hoverStyle={{...noneHoverStyles}}>
          {t('none')}{' '}
        </div>
      )}
    </div>
  );
};

export default SychartOptions;
