import {createConsumer} from '@rails/actioncable';
import {useRef, useState, useEffect} from 'react';

const useActionCable = (token, channel, reFetch) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/cable?token=${token}`;
  const consumer = useRef(null);
  const [loading, setLoading] = useState(false);
  const lastMessage = useRef(null);
  const wait = useRef(0);
  const [isbtnDisable, setIsBtnDisable] = useState(true);
  const [isInputDisable, setIsInputDisable] = useState(false);

  const onMessage = message => {
    setIsBtnDisable(true);
    setIsInputDisable(false);

    if (lastMessage.current?.chat_message_id === message?.chat_message_id) {
      return;
    }
    lastMessage.current = message;
  };
  useEffect(() => {
    if (token) {
      consumer.current = createConsumer(URL);
      consumer.current.subscriptions.create(
        {
          channel,
        },
        {
          connected: () => {
            console.log('connected');
            setLoading(false);
          },
          disconnected: () => console.log('disconnected'),
          received: onMessage,
        },
      );
    }
  }, [token]);

  const getMessage = async () => {
    if (wait.current === 20000) {
      wait.current = 0;
      reFetch();
      setIsInputDisable(false);
      return null;
    }

    return new Promise(resolve => {
      if (lastMessage.current) {
        const msg = lastMessage.current;
        lastMessage.current = null;
        resolve(msg);
        setIsInputDisable(false);
      } else {
        wait.current += 500;
        setTimeout(async () => {
          const msg = await getMessage();
          resolve(msg);
          setIsInputDisable(false);
        }, 500);
      }
    });
  };
  return {
    loading,
    getMessage,
    setIsBtnDisable,
    isbtnDisable,
    isInputDisable,
    setIsInputDisable,
  };
};

export default useActionCable;
